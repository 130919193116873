.custom-cake-item-container {
    display: flex; 
    border-radius: 24px;
    background-color: var(--old-lace);
    min-height: 160px;
    margin-bottom: 8px;
    margin-top: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
}

.step-container {
    padding-right: 16px;
    padding-left: 16px;
    width: 100%;
}

.step-container h3 {
    font-family: var(--headings-font);
    font-weight: 600;
    font-size: 24px;
}

.step-container-content-descriptor {
    color:  var(--gray-color);
    font-size: 16px;
    font-family: var(--main-font);
}

.step-container ul {
    list-style-type: circle;
}

.step-container li {
    font-size: 16px;
    font-family: var(--main-font);
    margin-left: 24px;
}

.step-container-text {
    color:  var(--gray-color);
    font-size: 16px;
    font-family: var(--main-font);
}

.vertical-breaker {
    border: none;
    border-left: 1px dashed var(--gray-color);
}

.custom-cake-bottom-margin {
    margin-bottom: 0px;
}

@media screen and (max-width: 800px) {
    .custom-cake-item-container {
        flex-direction: column;
        margin-left: 8px;
        margin-right: 8px;
        width: auto;
    }

    .custom-cake-bottom-margin {
        margin-bottom: 16px;
    }
}