.change-language-container {
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-select {
    width: 30px;
    height: 25px;
    padding: 10px;
    font-size: 16px;
    font-family: var(--main-font);
    text-align: center;
    border: 0px solid;
    border-radius: 5px;
    background-color: var(--white-color);
    appearance: none;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s ease;
    position: relative;
}
  
.custom-select option {
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.image-selector-overlapping {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.image-selector-overlapping img {
    position: absolute;
    z-index: 100;
    width: 32px;
    height: 32px;
    pointer-events: none;
}