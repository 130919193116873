.Contact {
    background-color: var(--white-color);
    width: 100vw;
}

.contact-empty-fullscreen {
    height: 100vh;
}

/* WHERE TO FIND US */

.location-information-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.location-information-description {
    max-width: 600px;
    margin-right: 32px;
}

.location-buttons-container {
    margin-top: 32px;
}

.store-image-carousel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 10px;
    margin-top: 48px;
}

iframe {
    width: 100%;
    height: 300px;
    border-radius: 12px;
}

/* CONTACT */

.contact-section {
    width: 100%;
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--old-lace);
    padding: 16px;
    border-radius: 16px;
}

label {
    margin-bottom: 4px;
    margin-top: 16px;
}

input, textarea {
    border-radius: 8px;
    border: none;
    padding: 4px;
    font-family: var(--main-font);
    font-size: 16px;
}

label {
    font-size: 16px;
    font-family: var(--main-font);
}

input {
    height: 32px;
}

textarea {
    height: 100px;
    resize: none;
}

button {
    margin-top: 24px;
    font-family: var(--main-font);
    font-size: 16px;
}

/* LOCATION */

.location-contact-section {
    margin: 24px;
}

/* IMAGES */

.images-contact-section {
    margin: 24px;
}

@media screen and (max-width: 800px) {
    .contact-section {
        padding: 16px;
    }
}

@media screen and (max-width: 1100px) {
    .location-information-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .location-buttons-container {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .store-image-carousel {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 10px;
        margin-top: 48px;
    }

    .location-section {
        margin: 24px;
    }
}