:root {
    --fairy-tale: #e7bfd0;
    --antiflash-white: #edeeee;
    --olivine: #a0b07e;
    --tea-rose: #e9bab4;
    --ecru: #b2ae5a;
    --feldgrau: #4b6668;
    --lion: #bf8f5b;
    --brown-sugar: #a76a50;
    --fawn: #f0ad76;
    --dun: #d6c69d;
    --african-violet: #bd7fc3;
    --burgundy: #7B1B26;
}

.fairy-tale {
    background-color: var(--fairy-tale);
}

.antiflash-white {
    background-color: var(--antiflash-white);
}

.olivine {
    background-color: var(--olivine);
}

.tea-rose {
    background-color: var(--tea-rose);
}

.ecru {
    background-color: var(--ecru);
}

.feldgrau {
    background-color: var(--feldgrau);
}

.lion {
    background-color: var(--lion);
}

.brown-sugar {
    background-color: var(--brown-sugar);
}

.fawn {
    background-color: var(--fawn);
}

.dun {
    background-color: var(--dun);
}

.african-violet {
    background-color: var(--african-violet);
}

.burgundy {
    background-color: var(--burgundy);
}