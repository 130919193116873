.cake-list-container {
    margin-top: 16px;
}

.cake-list-container-grid {
    display: grid;
    grid-template-columns: auto auto auto;
}

.spacer li {
    width: 300px;
}

.see-all-products-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}

@media screen and (max-width: 900px) {
    .cake-list-container-grid {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 600px) {
    .cake-list-container-grid {
        grid-template-columns: auto;
    }
}
