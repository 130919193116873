.cake-entry-section-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.cake-entry-section-products-container {
    display: flex;
    flex-direction: row;
}

.cake-entry-section-container .description {
    text-align: center;
}

.cake-entry-section-products-container-item {
    margin: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cake-entry-section-products-container-item img {
    border-radius: 8px;
}

.cake-entry-section-products-container-item h2 {
    margin-top: 10px;
}

@media screen and (max-width: 1100px) {
    .cake-entry-section-products-container-item h2 {
        font-size: 18px;
    }
}

@media screen and (max-width: 800px) {
    .cake-entry-section-products-container-item h2 {
        font-size: 14px;
    }
}

@media screen and (max-width: 600px) {
    .cake-entry-section-products-container {
        flex-direction: column;
    }

    .cake-entry-section-products-container-item h2 {
        font-size: 22px;
    }
}
