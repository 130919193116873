/* APP */

.Home {
    background-color: var(--white-color);
    width: 100vw;
}

/* HERO */

.main-hero-section {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  /* background-image: url('https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/background.svg?alt=media&token=98bb5ad0-1ad7-4a89-9f7d-5f0aea4af826'); */
  background-size: contain;
  background-clip: padding-box;
  background-repeat: no-repeat;
  background-position-y: 30%;
  padding: 16px;
}
  
.main-hero-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
  
.icons-container-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}
  
.icon-container-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  margin: 20px;
}
  
.icon {
  height: 95px;
  width: 95px;
}
  
.icon-container-home p {
  max-width: 160px;
  height: 150px;
  text-align: center;
  margin-top: 10px;
}
  
.icon-container-home h2 {
  margin-top: 16px;
}

.main-hero-content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;
}
  
.main-hero-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
  
.main-hero-text-container p {
margin-right: 30px;
}
  
.main-hero-text-container h1 {
  text-align: left;
}

.main-hero-content-image {
  width: 25%;
  border-radius: 8px;
  max-width: 300px;
}
  
  /* CAKES */
  
.cakes-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
  
.cakes-section p {
    text-align: center;
}
  
  /* WEDDINGS */
  
.wedding-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 16px;
}

.wedding-section p {
  text-align: center;
}

.wedding-image-container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
}

.wedding-image-container img {
  width: 40%;
  border-radius: 8px;
}

.wedding-description-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px;
}

.wedding-description-container p {
  text-align: left;
}

.wedding-description-container a {
  margin-left: 0px;
  margin-top: 26px;
}
  
@media screen and (max-width: 850px) {
  .wedding-image-container img {
    width: 50%;
  }

  .main-hero-content-image {
    width: 100%;
    max-width: 300px;
  }
}

@media screen and (max-width: 800px) {
  .main-hero-section {
    margin-top: 100px;
  }
    
  .wedding-image-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .wedding-description-container p {
    text-align: center;
  }

  .wedding-description-container {
    align-items: center;
    justify-content: center;
  }

  .main-hero-text-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
  }

  .main-hero-buttons-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .main-hero-content-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icons-container-home {
    flex-direction: column;
    margin-top: 20px;
  }

  .icon-container-home p {
    height: auto;
  }

  .icon-container-home h2 {
    margin-top: 16px;
  }

  .icon-container-home {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .description p {
    margin: 0px;
  }

  .wedding-description-container {
    margin: 0px;
  }

  .wedding-image-container {
    margin-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .wedding-image-container img {
    width: 100%;
  }

  .description p {
    padding: 0px;
  }
}