.cakes-custom-section {
    margin-right: 16px;
    margin-left: 16px;
}

.steps-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.step-image-text-container {
    background-color: var(--dark-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 4px;
    border-radius: 20px;
}

.step-text {
    margin-top: 8px;
    font-size: 20px;
    font-family: var(--main-font);
    font-weight: 600;
    color: var(--white-color);
    text-transform: uppercase;
    width: 100%;
    text-align: center;
}

.step-image {
    width: 60px;
    height: 60px;
}

.custom-cake-items-list {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
}

@media screen and (max-width: 800px) {
    .custom-cake-items-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .cakes-custom-section {
        margin-right: 8px;
        margin-left: 8px;
    }

    .step-text {
        font-size: 14px;
    }

    .step-image-text-container {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .step-image {
        width: 35px;
        height: 35px;
    }
}

@media screen and (max-width: 400px) {
    .custom-cake-items-list {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}