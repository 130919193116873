*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --main-font: "Open Sans", sans-serif;
    --headings-font: "Noto Serif", serif;
    --old-lace: #ECECE2;
    --old-lace-light: #DAD5C7;
    --old-lace-contrast: #D1A759;
    --white-color: #F5F6F8;
    --dark-color: #232E3E;
    --dark-color-alpha: #232E3E70;
    --gray-color: #C4C0B3;
}

html {
    font-size: 10px;
    color: var(--body-font-color);
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 300px;
}

section {
    padding-top: 3.9rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

img {
    width: 100%;
    max-width: 100%; 
}

a {
    font-family: var(--main-font);
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

ul {
    list-style: none;
}

h1 {
    font-size: 48px;
    font-weight: 600;
    font-family: var(--headings-font);
    text-align: center;
    line-height: 55px;
    margin-bottom: 16px;
}

h2 {
    font-size: 26px;
    font-weight: 400;
    font-family: var(--headings-font);
    text-align: center;
}

h3 {
    font-size: 18px;
    font-weight: 500;
    font-family: var(--main-font);
}

p {
    font-size: 16px;
    font-family: var(--main-font);
}

hr {
    border: none;
    border-top: 1px dashed var(--gray-color);
    color: var(--old-lace);
    background-color: var(--old-lace);
    height: 1px;
    width: 100%;
}

/* SHARED COMPONENTS */

.first-section {
    margin-top: 100px;
}

.tag {
    background-color: var(--old-lace-light);
    border-radius: 12.5px;
    height: 25px;
    text-align: center;
    margin: auto;
    color: var(--dark-color);
    display: inline-flex;;
    align-items: center;
    justify-content: center;
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 1;
}

.caption {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 5px 10px;
}

.button-primary {
    background-color: var(--dark-color);
    border: 3px solid var(--dark-color);
    color: var(--old-lace);
}

.button-secondary {
    background-color: var(--white-color);
    border: 3px solid var(--dark-color);
    color: var(--dark-color);
}

.button-tertiary {
    background-color: var(--old-lace);
    color: var(--dark-color);
}

.button {
    padding: 10px 20px;
    border-radius: 22.5px;
    height: 45px;
    text-transform: uppercase;
    margin: 10px;
}

.button:hover {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
}

.container {
    max-width: 1100px;
    width: 100%;
    margin-top: 60px;
    margin-left: 60px;
    margin-right: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 20px;
}

.see-all-button {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
}

.error-message {
    font-family: var(--main-font);
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    color: var(--old-lace-contrast);
    margin-top: 14px;
    margin-bottom: 6px;
    text-align: center;
}

.success-message {
    font-family: var(--main-font);
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    color: var(--olivine);
    margin-top: 14px;
    margin-bottom: 6px;
    text-align: center;
}

@media screen and (max-width: 1150px) {
    .first-section {
        margin-top: 145px;
    }
}

@media screen and (max-width: 800px) {
    h1 {
        text-align: center;
        font-size: 32px;
        font-weight: 500;
        line-height: 45px;
        margin-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
    
    h2 {
        font-size: 22px;
    }
    
    p {
        font-size: 15px;
    }

    .caption {
        font-size: 12px;
    }

    .button {
        padding: 5px 10px;
        border-radius: 19px;
        height: 38px;
        margin: 5px;
        font-size: 14px;
    }

    .container {
        min-width: 300px;
        margin: 0px;
    }
}