.cake-item-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border-radius: 24px 24px 24px 24px;
    background-color: var(--old-lace);
}

.cake-item-icon-image {
    height: 48px;
    width: 48px;
    margin: 16px;
}

.cake-item-icon-description-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-top: 8px;
    min-height: 170px;
    display: flex;
    flex-direction: column;
}

.cake-item-icon-title {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    flex-direction: column;
    width: 100%;
}

.cake-item-icon-title h4 {
    color: var(--dark-color);
    font-size: 19px;
    font-weight: 600;
    font-family: var(--headings-font);
    text-align: left;
}

.cake-item-icon-description p {
    color: var(--dark-color);
    font-size: 14px;
    font-family: var(--main-font);
    text-align: left;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.cake-item-icon-description {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    flex-direction: column;
    width: 100%;
}

.cake-item-icon-info-tag {
    top: 8px;
    right: 8px;
    background-color: var(--old-lace-contrast);
    border-radius: 12.5px;
    height: 25px;
    text-align: center;
    margin: auto;
    color: var(--white-color);
    display: inline-flex;;
    align-items: center;
    justify-content: center;
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 1;
}

.cake-item-container hr {
    border: none;
    border-top: 1px dashed var(--gray-color);
    color: var(--old-lace);
    background-color: var(--old-lace);
    height: 1px;
    width: 100%;
  }

  .cake-item-icon-tag-container {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    flex-direction: column;
    width: 100%;
}

.cake-item-icon-tag-container p {
    text-align: start;
    margin-top: 6px;
    font-style: italic;
    font-size: 12px;
}

@media screen and (max-width: 800px) {
    .cake-item-image-container {
        height: 200px;
    }

    .cake-item-description-container {
        height: auto;
    }
}