.nav-link {
    display: block;
    color: #1A1A1A;;
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: -2px;
    transition: color .5s;
}

.nav-link:hover {
    font-weight: 600;
}

.active {
    position: relative;
}

.nav-item {
    margin: 10px;
}