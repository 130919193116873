footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-color);
    color: var(--light-color);
    padding: 20px;
    width: 100%;
}

.footer-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-contact-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: 20px;
}

.footer-contact {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.footer-contact h3 {
  text-transform: uppercase;
  margin: 5px;
  color: var(--white-color);
}

.footer-contact a {
  color: var(--white-color);
}

.footer-contact p {
  margin: 5px;
  color: var(--white-color);
}

.footer-logo-image {
  height: 100px;
  width: auto;
}

.footer-top-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
}

.footer-instagram-logo {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.footer-whatsapp-logo {
  width: 40px;
  height: 40px;
}

.instagram-language-container {
  display: flex;
}

@media screen and (max-width: 1000px) {
  .footer-contact-container {
    flex-direction: column;
  }
}