.gallery-images-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gallery {
    column-count: 3;
    column-gap: 10px;
    margin: 10px;
}
  
.gallery-item {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
}
  
.gallery-item img {
    max-width: 100%;
    display: block;
    grid-row: 1 / -1;
    grid-column: 1;
    border-radius: 6px;
}

@media screen and (max-width: 800px) {
    .gallery {
        column-count: 2;
    }
}