header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--white-color);
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-family: var(--main-font);
    z-index: 1000;
}

.navbar {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
    max-width: 1150px;
}

.logo {
    height: 80px;
    width: auto;
}

.nav-list {
    display: flex;
    flex-direction: row;
}

.active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #1A1A1A;;
    left: 0;
    bottom: -3px;
}

.active:hover {
    font-weight: 400;
}

@media screen and (max-width: 1150px) {
    .navbar {
        flex-direction: column;
    }

    .logo {
        height: 55px;
        width: auto;
    }
}