.Weddings {
  background-color: var(--white-color);
  width: 100vw;
}

/* HERO */

.hero-section {
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    /* background-image: url('https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/background.svg?alt=media&token=98bb5ad0-1ad7-4a89-9f7d-5f0aea4af826'); */
    background-size: contain;
    background-clip: padding-box;
    background-repeat: no-repeat;
    background-position-y: 30%;
}

.hero-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.icons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.icon {
    height: 95px;
    width: 95px;
}

.icon-container p {
    max-width: 280px;
    height: 80px;
}

.hero-content-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-top: 40px;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.text-container p {
  margin-right: 30px;
}

.hero-content-container img {
  width: 40%;
  border-radius: 8px;
}

.icon-container p {
    text-align: center;
}

.text-container h1 {
  text-align: left;
}

/* MENU */

.cake-list-wedding-container-list {
  margin-top: 48px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.cake-item-wedding-container hr {
  border: none;
  border-top: 1px dashed var(--gray-color);
  color: var(--old-lace);
  background-color: var(--old-lace);
  height: 1px;
  width: 100%;
}

.cake-item-wedding-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px;
  padding: 10px;
  border-radius: 24px;
  background-color: var(--old-lace);
  padding: 16px;
  height: 250px;
}

.spacer li {
  width: 300px;
}

/* BAKERY MASTER */

.bakery-master-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-bottom: 24px;
}
  
.bakery-master-section p {
    text-align: center;
}
  
.bakery-master-image-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}
  
.bakery-master-image-container img {
    width: 20%;
    border-radius: 44px;
}

.bakery-master-description-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px;
}

.bakery-master-description-container p {
    text-align: left;
}

.bakery-master-description-container a {
    margin-left: 0px;
    margin-top: 26px;
}

  /* MEDIA QUERIES */

@media screen and (max-width: 900px) {
  .cake-list-wedding-container-list {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 600px) {
  .cake-list-wedding-container-list {
    grid-template-columns: auto;
}
}

@media screen and (max-width: 900px) {
  .text-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
  }

  .hero-buttons-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .hero-content-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hero-content-container img {
    width: 100%;
    max-width: 300px;
  }

  .icons-container {
    flex-direction: column;
    margin-top: 20px;
  }

  .icon-container p {
    height: auto;
  }

  .description p {
    padding: 16px;
    margin: 0px;
  }

  .bakery-master-image-container {
    flex-direction: column;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
  }

  .bakery-master-image-container img {
    width: 80%;
    margin: 0px;
    border-radius: 8px;
  }

  .cake-item-wedding-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
  }
}