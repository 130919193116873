.reviews-section {
    display: flex;
    flex-direction: row;
}

.stars-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
}

.star {
    height: 19px;
    width: 20px;
}

.reviews-container {
    display: flex;
    flex-direction: row;
    display: grid;
    grid-template-columns: auto auto;
}

.review-container {
    margin: 10px;
    padding: 24px;
    border-radius: 24px;
    background-color: var(--old-lace);
}

.see-all-reviews-container {
    margin-top: 16px;
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}


@media screen and (max-width: 800px) {
    .reviews-container {
        grid-template-columns: auto;
    }
}